import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { Collapse } from 'bootstrap'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/autor',
    name: 'Autor',
    // route level code-splitting
    // this generates a separate chunk (autor.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "autor" */ '../views/Autor.vue')
  },
  {
    path: '/proyecto',
    name: 'Proyecto',
    // route level code-splitting
    // this generates a separate chunk (proyecto.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "proyecto" */ '../views/Proyecto.vue')
  },
  {
    path: '/libros',
    name: 'Libros',
    // route level code-splitting
    // this generates a separate chunk (libros.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "libros" */ '../views/Libros.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach(() => {
  // scroll top
  window.scrollTo(0, 0)
  // Bootstrap navbar collapse hide
  const navbarCollapse = document.querySelector('#menu')
  const bsCollapse = new Collapse(navbarCollapse, {
    toggle: false
  })
  bsCollapse.hide()
})

export default router
