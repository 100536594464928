<template>
  <section>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="ratio ratio-16x9">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/mkqCY8MDQEc?rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted } from 'vue'
import { useMeta } from 'vue-meta'
export default {
  name: 'Home',
  setup () {
    useMeta({
      og: {
        url: window.location.href
      }
    })
    onMounted(() => {
      document.body.style.backgroundColor = '#5775ba'
      document.body.style.backgroundImage = ''
    })
  }
}
</script>
