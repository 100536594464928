<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content
        ? `${content} | Georges Simenon`
        : `Georges Simenon | Acantilado y Anagrama se unen para dar continuidad a la publicación de las obras del gran escritor belga.`
    }}</template>
  </metainfo>
  <header>
    <nav class="container navbar-expand-md">
      <div class="row align-items-center">
        <div class="col">
          <router-link :to="{ name: 'Home' }">
            <img class="img-fluid" src="@/assets/logo.png" alt="GEORGES SIMENON">
          </router-link>
        </div>
        <button class="col-auto navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu" aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation">
          <i class="bi bi-list"></i>
          <i class="bi bi-x"></i>
        </button>
      </div>
      <div class="row">
        <div class="col">
          <div class="collapse navbar-collapse" id="menu">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <router-link class="nav-link px-md-4" :to="{ name: 'Home' }">INICIO</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link px-md-4" :to="{ name: 'Autor' }">EL AUTOR</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link px-md-4" :to="{ name: 'Proyecto' }">EL PROYECTO</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link px-md-4" :to="{ name: 'Libros' }">LOS LIBROS</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <main>
    <router-view/>
  </main>
  <footer>
    <div class="container" v-bind:class="{'d-block d-md-none' : $route.name == 'Proyecto'}">
      <div class="row justify-content-center">
        <div class="col-8 col-sm d-flex align-items-center justify-content-center">
          <img loading="lazy" class="img-fluid mx-1" width="139" height="17" src="@/assets/logo-anagrama.svg" alt="Anagrama">
          <img loading="lazy" class="img-fluid mx-1" width="13" height="13" src="@/assets/et.svg" alt="&amp;">
          <img loading="lazy" class="img-fluid mx-1" width="186" height="17" src="@/assets/logo-acantilado.svg" alt="Acantilado">
        </div>
      </div>
    </div>
    <div class="mt-5">
      <small><a href="#" data-cc="c-settings">Cookies</a></small>
    </div>
  </footer>
</template>

<script>
import { useMeta } from 'vue-meta'
export default {
  name: 'App',
  setup () {
    useMeta({
      htmlAttrs: {
        lang: 'es'
      },
      title: '',
      description:
        'Acantilado y Anagrama se unen para dar continuidad a la publicación de las obras del gran escritor belga.',
      og: {
        title: 'Georges Simenon | Acantilado y Anagrama se unen para dar continuidad a la publicación de las obras del gran escritor belga.',
        type: 'website',
        url: window.location.href,
        image: `${
          window.location.origin
        }${require('@/assets/logo.png')}`,
        description:
          'Acantilado y Anagrama se unen para dar continuidad a la publicación de las obras del gran escritor belga.',
        locale: 'es_ES',
        site_name: 'Georges Simenon'
      },
      twitter: {
        title: 'Georges Simenon | Acantilado y Anagrama se unen para dar continuidad a la publicación de las obras del gran escritor belga.',
        card: 'website',
        image: `${
          window.location.origin
        }${require('@/assets/logo.png')}`,
        description:
          'Acantilado y Anagrama se unen para dar continuidad a la publicación de las obras del gran escritor belga.'
        // site: '@{username}'
      }
    })
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500;600&family=PT+Serif:wght@400;700&display=swap');
  @import './sass/abstracts/mixins';
  @import './sass/vendors/bootstrap';
  body {
    background-repeat: no-repeat;
    background-position: left 60px;
    background-attachment: fixed;
    background-size: 20%;
    @include media-breakpoint-down(md) {
        background-image: none !important;
    }
  }
  #app {
    font-family: 'PT Serif', serif;
    color: #000;
  }
  a {
    color: inherit;
    @include on-event() {
        color: inherit;
        text-decoration: none;
    }
  }
  .text-justify {
    text-align: justify;
  }

  :root{
    --cc-btn-primary-bg: #000000;
    --cc-text: #000000;
  }

  #cm,
  #s-inr,
  .cc_div .c-bn {
    border-radius: 0;
  }
</style>

<style lang="scss" scoped>
  @import './sass/vendors/bootstrap';
  header, footer {
    text-align: center;
  }
  header {
    padding: 30px 0;
  }
  footer {
    padding: 60px 0;
  }
  .navbar-toggler {
    font-size: 30px;
    box-shadow: none !important;
    color:#fff;
    &[aria-expanded="false"] {
      .bi-list {
        display: block;
      }
      .bi-x {
        display: none;
      }
    }
    &[aria-expanded="true"] {
      .bi-list {
        display: none;
      }
      .bi-x {
        display: block;
      }
    }
  }
  .navbar-nav {
    padding: 30px 0;
    .nav-link {
      font-family: 'Barlow Condensed', sans-serif;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 4px;
      color: inherit;
      transition: margin-top 0.25s;
      @include media-breakpoint-up(md) {
        &:hover {
          margin-top: -3px;
        }
      }
      &:focus,
      &:active,
      &.router-link-exact-active {
        color: #fff;
      }
    }
  }
</style>
